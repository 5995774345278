






import { Component, Vue } from 'vue-property-decorator';
import HebrewTest from '@/components/HebrewTest.vue'; // @ is an alias to /src

@Component({
  components: {
    HebrewTest,
  },
})
export default class Home extends Vue {
}
