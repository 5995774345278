











































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import { VueSelect } from 'vue-select';
import sourcedata from '@/data/sources'

Vue.component('v-select', VueSelect)


interface VerseData {
    text: string[];
    he: string[];
}

interface RefComponents {
  book: string;
  chapter: string;
}

@Component({
})
export default class HelloWorld extends Vue {

  private verses: VerseData = { he: [], text: []};
  private next?: string = "";
  private prev?: string = "";

  private chapterCount = 1;

  private book: string = this.targetBook;
  private chapter: string = this.targetChapter;

  private allBooks = [
    "Genesis",
    "Exodus",
    "Leviticus",
    "Numbers",
    "Deuteronomy"
  ]

  get targetBook(): string {
    return this.$route.params.book;
  }

  get targetChapter(): string {
    return this.$route.params.chapter;
  }

  async updateChapter(): Promise<void> {
    try {
      const book = this.targetBook;
      const chapter = this.targetChapter;
      const version = "The Holy Scriptures: A New Translation (JPS 1917)";
      const response = await this.$http.get(
        `https://www.sefaria.org/api/texts/${book} ${chapter}/en/${version}`
      )
      this.book = book;
      this.chapter = chapter;
      this.verses = {
        he: response.data.he,
        text: response.data.text,
      }
      this.next = response.data.next;
      this.prev = response.data.prev;
      this.chapterCount = response.data.length;
    } catch(err) {
      console.error(err);
    }
  }

  async mounted(): Promise<void> {
    await this.updateChapter();
  }

  @Watch('$route')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRouteUpdate(to: Route, from: Route): void {
    this.updateChapter();
  }

  parseRef(refstr: string): RefComponents | null {
    if (!refstr) {
      return null;
    }
    const re = /(.*?)\s+(\d+)/;
    const m = refstr.match(re);
    if (m) {
      return {
        book: m[1],
        chapter: m[2],
      };
    } else {
      return null;
    }
  }

  gotoRef(ref: string): void {
    const rc = this.parseRef(ref);
    if (rc) {
      const { book, chapter } = rc;
      this.$router.push({ name: "Text", params: { book, chapter }});
    }
  }

  gotoBook(book: string): void {
    const chapter = "1";
    this.$router.push({ name: "Text", params: { book, chapter}});
  }

  gotoChapter(chapter: string): void {
    const book = this.book;
    this.$router.push({ name: "Text", params: { book, chapter}});
  }

  get allChapters(): string[] {
    const chapters = [];
    for (let i=1; i<=this.chapterCount; i++) {
      chapters.push(String(i));
    }
    return chapters;
  }

  cssVars(): { [key:string]: string } {
    return {
      '--he-serif-font' : 'Taamey Frank',
      '--en-serif-font' : 'Garamond',
      '--color-J' : 'green',
      '--color-E' : 'orange',
      '--color-P' : 'blue',
      '--color-R' : 'maroon',
      '--color-D1' : 'red',
      '--color-D2' : 'darkred',
      '--color-Dn' : 'magenta',
      '--color-O' : 'indigo'
    }
  }

  verseFragments(verseIndex: number, lang: 'text' | 'he'): string[] {
    const verseText = this.verses[lang][verseIndex];
    const chapter = parseInt(this.chapter);
    const chapterSplits = sourcedata[this.book].splits[chapter];
    if (chapterSplits) {
      const splits = chapterSplits[verseIndex+1];
      if (splits) {
        const words = verseText.split(/\s+/);
        const results = [];
        let lastIdx = 0;
        for (let idx of splits) {
          results.push(words.slice(lastIdx, idx).join(' '));
          lastIdx = idx;
        }
        results.push(words.slice(lastIdx).join(' '));
        return results;
      }
    }
    return [verseText];
  }

  fragmentSource(verseIndex:number, fragIndex = 0): string {
    const chapter = parseInt(this.chapter);
    const data = sourcedata;
    const chapterSources = data[this.book].sources[chapter-1];
    const verseSources = chapterSources[verseIndex];
    return `${verseSources[fragIndex]}`
  }

  get allChapterSources(): string[] {
    const chapterIndex = parseInt(this.chapter) - 1;
    return [...new Set(sourcedata[this.book].sources[chapterIndex].flat())];
  }
}
