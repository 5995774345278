import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { AxiosStatic } from "axios";

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

// Make the compiler happy
declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosStatic;
  }
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
